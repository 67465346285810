import { cn } from "core/helpers";
import { FC, DetailedHTMLProps, ButtonHTMLAttributes } from "react";

const Button: FC<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      className={cn(
        "outline-none select-none flex items-center justify-center disabled:pointer-events-none disabled:bg-grey3 transition duration-500 ease-in-out",
        className
      )}
    >
      {children}
    </button>
  );
};

export default Button;
