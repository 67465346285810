import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { firebaseAuth, firebaseStorage, firestoreDB } from "ftp-firebase/firebaseConfig";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import { getFunctions, httpsCallable } from "firebase/functions";

/**
 * Asynchronously signs in users using an email and password.
 * @param email - The users email address.
 * @param password - The users password.
 */
export const loginUserWithEmailAndPassword = async (email: string, password: string) => {
  const result = await signInWithEmailAndPassword(firebaseAuth, email.toLowerCase(), password);
  return result;
};

/**
 * Signs out logged-in user.
 */
export const logOutUser = async () => {
  await signOut(firebaseAuth);
};

export const getMarkerIcons = async () => {
  const result = await getDocs(collection(firestoreDB, "markerIcons"));
  return result;
};

export const saveNewMarker = async (data: MarkersTypes) => {
  const result = await addDoc(collection(firestoreDB, "markers"), data);
  return result;
};

export const updateMarker = async (docId: string, data: MarkersTypes) => {
  await updateDoc(doc(firestoreDB, "markers", docId), data);
};

export const getSharedMarkerData = async (title: string) => {
  const collectionRef = collection(firestoreDB, "markers");
  const q = query(collectionRef, where("heading.en", "==", title));
  const result = await getDocs(q);
  return result;
};

export const getSharedMarkerGroup = async (groupName: string) => {
  const collectionRef = collection(firestoreDB, "markers");
  const q = query(collectionRef, where("group", "==", groupName));
  const result = await getDocs(q);
  return result;
};

export const deleteMarker = async (id: string) => {
  await deleteDoc(doc(firestoreDB, "markers", id));
};

export const addNewNotifications = async (title: string, description: string, users: any) => {
  const result = await addDoc(collection(firestoreDB, "notifications"), {
    title,
    description,
    users,
    createdAt: serverTimestamp(),
  });
  return result;
};

export const updateNotifications = async (id: string, data: any) => {
  await updateDoc(doc(firestoreDB, "notifications", id), data);
};

export const deleteNotifications = async (id: string) => {
  await deleteDoc(doc(firestoreDB, "notifications", id));
};

export const getNFTOwners = async () => {
  const result = await getDocs(collection(firestoreDB, "nftOwners"));
  return result;
};

export const getNFTTransfers = async () => {
  const result = await getDocs(collection(firestoreDB, "nftTransfers"));
  return result;
};

export const getRFTByTokenHash = async (tokenHash: string) => {
  const q = query(collection(firestoreDB, "nftOwners"), where("token_hash", "==", tokenHash));
  const result = await getDocs(q);
  return result;
};

export const updateRFTData = async (id: string, data: NFTItemType) => {
  await updateDoc(doc(firestoreDB, "nftOwners", id), data);
};

// admin users functionalities
export const addAdminUser = async (data: any, password: string) => {
  const result = await addDoc(collection(firestoreDB, "adminUsers"), data);

  const userRef = doc(firestoreDB, `adminUsers/${result.id}/privateData`, result.id);

  await setDoc(userRef, { password });

  return result;
};

export const getAdminUserDetailsById = async (id: string) => {
  const result = await getDoc(doc(firestoreDB, "adminUsers", id));
  return result;
};

export const updateAdminUserDetails = async (id: string, data: any) => {
  await updateDoc(doc(firestoreDB, "adminUsers", id), data);
};

export const updateAdminUserPassword = async (id: string, password: string) => {
  await updateDoc(doc(firestoreDB, "adminUsers", id, "privateData", id), { password });
};

export const deleteAdminUser = async (id: string) => {
  await deleteDoc(doc(firestoreDB, "adminUsers", id, "privateData", id));
  await deleteDoc(doc(firestoreDB, "adminUsers", id));
};

export const sendForgotPasswordEmail = async (email: string) => {
  await sendPasswordResetEmail(firebaseAuth, email.toLowerCase());
};

export const resetPassword = async (code: string, password: string) => {
  await confirmPasswordReset(firebaseAuth, code, password);
};

// users
export const signUpUserWithEmailAndPassword = async (email: string, password: string) => {
  const result = await createUserWithEmailAndPassword(firebaseAuth, email.toLowerCase(), password);
  return result;
};

export const addUserWithEmailAndPassword = async (id: string, data: UserTypes) => {
  await setDoc(doc(firestoreDB, "users", id), { ...data, createdAt: serverTimestamp() });
};

export const loginUserWithWalletAddress = async (token: string) => {
  const result = await signInWithCustomToken(firebaseAuth, token);
  return result;
};

export const getUsers = async () => {
  const result = await getDocs(collection(firestoreDB, "users"));
  return result;
};

export const updateUserDetails = async (id: string, data: UserTypes) => {
  const result = await updateDoc(doc(firestoreDB, "users", id), data);
  return result;
};

export const getUserDetailsById = async (id: string) => {
  const result = await getDoc(doc(firestoreDB, "users", id));
  return result;
};

export const getUserDetailsByEmail = async (email: string) => {
  const q = query(collection(firestoreDB, "users"), where("email", "==", email.toLowerCase()));
  const result = await getDocs(q);
  return result.docs[0];
};

export const uploadProfileImage = async (image: File, userId?: string) => {
  let imageName = image.name;
  const extension = imageName.split(".").pop();
  const name = imageName.split(".").slice(0, -1).join(".");

  const userIdForImageName = userId ? userId + "_" : "";

  imageName = name + "_" + userIdForImageName + moment(Date.now()).format("YYYY-MM-DD_hh-mm-ss") + "." + extension;
  const storageRef = ref(firebaseStorage, "/profileImages/" + imageName);
  const response = await uploadBytes(storageRef, image);
  const url = await getDownloadURL(response.ref);
  return url;
};

export const getUserProfileImage = async (imageURL: string) => {
  try {
    const imageRef = ref(firebaseStorage, imageURL);
    await getDownloadURL(imageRef);
    return true;
  } catch (error) {
    return false;
  }
};

export const deletePreviousProfileImage = async (imageURL: string) => {
  const imageRef = ref(firebaseStorage, imageURL);
  const deleteImageRef = ref(firebaseStorage, imageRef.fullPath);
  await deleteObject(deleteImageRef);
};

export const addTemporaryProtectorDetails = async (data: TemporaryProtectorTypes) => {
  const result = await addDoc(collection(firestoreDB, "temporaryProtectors"), {
    ...data,
    createdAt: serverTimestamp(),
  });

  return result;
};

export const uploadTemporaryProtectorProfileImage = async (image: File) => {
  let imageName = image.name.trim();
  const extension = imageName.split(".").pop();
  const name = imageName.split(".").slice(0, -1).join(".");
  imageName = name + "_" + moment(Date.now()).format("YYYY-MM-DD_hh-mm-ss") + "." + extension;
  const storageRef = ref(firebaseStorage, "/temporaryProtectorProfileImages/" + imageName);
  const response = await uploadBytes(storageRef, image);
  const url = await getDownloadURL(response.ref);
  return url;
};

// language
export const updateLanguageAvailability = async (id: string, data: any) => {
  await updateDoc(doc(firestoreDB, "translations", id), data);
};

export const updateLanguageLocales = async (id: string, data: any) => {
  await updateDoc(doc(firestoreDB, "translations", id), data);
};

// B2B RFT editions
export const addB2BCustomer = async (id: string, data: B2BCustomerTypes) => {
  await setDoc(doc(firestoreDB, "B2BCustomers", id), data);
};

export const updateB2BCustomer = async (id: string, data: B2BCustomerTypes) => {
  await updateDoc(doc(firestoreDB, "B2BCustomers", id), data);
};

export const uploadB2BCustomerLogo = (id: string, image: File) => {
  let imageName = image.name;
  const extension = imageName.split(".").pop();
  const name = imageName.split(".").slice(0, -1).join(".");

  imageName = name + "_" + id + "_" + moment(Date.now()).format("YYYY-MM-DD_hh-mm-ss") + "." + extension;
  const storageRef = ref(firebaseStorage, "/B2BCustomersLogo/" + imageName);
  const result = uploadBytesResumable(storageRef, image);
  return result;
};

export const deleteB2BCustomerLogo = async (logoURL: string) => {
  const imageRef = ref(firebaseStorage, logoURL);
  const deleteImageRef = ref(firebaseStorage, imageRef.fullPath);
  await deleteObject(deleteImageRef);
};

export const deleteB2bCustomer = async (id: string) => {
  await deleteDoc(doc(firestoreDB, "B2BCustomers", id));
};

export const getB2BCustomerByEdition = async (edition: string) => {
  const customerRef = collection(firestoreDB, "B2BCustomers");
  const q = query(customerRef, where("edition", "==", edition));
  const result = await getDocs(q);

  return result;
};

// Secret URL Analytics
export const addSecretURLVisitor = async (id: string, data: ShopifyAndSecretURLAnalyticsTypes) => {
  const result = await setDoc(doc(firestoreDB, "secretURLVisitors", id), data);
  return result;
};

export const getSecretURLVisitorById = async (id: string) => {
  const result = await getDoc(doc(firestoreDB, "secretURLVisitors", id));
  return result;
};

export const updateSecretURLVisitor = async (id: string, data: ShopifyAndSecretURLAnalyticsTypes) => {
  await updateDoc(doc(firestoreDB, "secretURLVisitors", id), data);
};

// Shopify URL Analytics
export const addShopifyURLVisitor = async (id: string, data: ShopifyAndSecretURLAnalyticsTypes) => {
  const result = await setDoc(doc(firestoreDB, "shopifyURLVisitors", id), data);
  return result;
};

export const getShopifyURLVisitorById = async (id: string) => {
  const result = await getDoc(doc(firestoreDB, "shopifyURLVisitors", id));
  return result;
};

export const updateShopifyURLVisitor = async (id: string, data: ShopifyAndSecretURLAnalyticsTypes) => {
  await updateDoc(doc(firestoreDB, "shopifyURLVisitors", id), data);
};

// Markers Group Names
export const addMarkersGroupName = async (data: MarkersGroupNameTypes) => {
  const result = await addDoc(collection(firestoreDB, "markersGroupNames"), data);
  return result;
};

export const updateMarkersGroupName = async (id: string, data: MarkersGroupNameTypes) => {
  await updateDoc(doc(firestoreDB, "markersGroupNames", id), data);
};

export const deleteMarkersGroupName = async (id: string) => {
  await deleteDoc(doc(firestoreDB, "markersGroupNames", id));
};

// campaigns
export const addCampaign = async (id: string, data: CampaignTypes) => {
  await setDoc(doc(firestoreDB, "campaigns", id), data);
};

export const updateCampaign = async (id: string, data: CampaignTypes) => {
  await updateDoc(doc(firestoreDB, "campaigns", id), data);
};

export const uploadCampaignImage = async (id: string, image: File) => {
  let imageName = image.name;
  const extension = imageName.split(".").pop();
  const name = imageName.split(".").slice(0, -1).join(".");

  imageName = name + "_" + id + "_" + moment(Date.now()).format("YYYY-MM-DD_hh-mm-ss") + "." + extension;
  const storageRef = ref(firebaseStorage, "/CampaignImages/" + imageName);
  const response = await uploadBytes(storageRef, image);
  const url = await getDownloadURL(response.ref);
  return url;
};

export const deleteCampaignImage = async (imageURL: string) => {
  const imageRef = ref(firebaseStorage, imageURL);
  const deleteImageRef = ref(firebaseStorage, imageRef.fullPath);
  await deleteObject(deleteImageRef);
};

export const deleteCampaign = async (id: string) => {
  await deleteDoc(doc(firestoreDB, "campaigns", id));
};

// campaign initiator
export const addCampaignInitiator = async (id: string, data: CampaignInitiatorsTypes) => {
  await setDoc(doc(firestoreDB, "campaignsInitiators", id), data);
};

export const updateCampaignInitiator = async (id: string, data: CampaignInitiatorsTypes) => {
  await updateDoc(doc(firestoreDB, "campaignsInitiators", id), data);
};

export const uploadCampaignInitiatorImage = (id: string, image: File) => {
  let imageName = image.name;
  const extension = imageName.split(".").pop();
  const name = imageName.split(".").slice(0, -1).join(".");

  imageName = name + "_" + id + "_" + moment(Date.now()).format("YYYY-MM-DD_hh-mm-ss") + "." + extension;
  const storageRef = ref(firebaseStorage, "/campaignInitiatorsImages/" + imageName);
  const result = uploadBytesResumable(storageRef, image);
  return result;
};

export const deleteCampaignInitiatorImage = async (logoURL: string) => {
  const imageRef = ref(firebaseStorage, logoURL);
  const deleteImageRef = ref(firebaseStorage, imageRef.fullPath);
  await deleteObject(deleteImageRef);
};

export const deleteCampaignInitiator = async (id: string) => {
  await deleteDoc(doc(firestoreDB, "campaignsInitiators", id));
};

export const claimSecretURLRFT = (token_hash: string, walletAddress: string, secret_url_type: string) => {
  const result = httpsCallable<
    { token_hash: string; walletAddress: string; secret_url_type: string },
    { status: number; message: string }
  >(getFunctions(), "claimSecretURLRFT");

  return result({ token_hash, walletAddress, secret_url_type });
};

// PNR Secret URL Analytics
/**
 * @param {string} id - ID of the Firestore document.
 * @param {ShopifyAndSecretURLAnalyticsTypes} data - data of the document.
 * @param {string} data.secretURLRFTId - RFT/PNR token title.
 * @param {string} data.ip - Public IP address of the rescuer.
 * @param {string} data.country - Country of the rescuer.
 * @param {string} data.date - Current date in YYYY-MM-DD format.
 * @param {string} data.time - Current time in hh:mm:ss AM/PM format.
 * @param {number} data.visited - Number of times this secret URL visited.
 * @param {number} data.buttonClickCount - Number of times rescue button clicked.
 */
export const addSecretURLPNRVisitor = async (id: string, data: ShopifyAndSecretURLAnalyticsTypes) => {
  await setDoc(doc(firestoreDB, "secretURLPNRVisitors", id), data);
};

/**
 * @param {string} id - ID of the Firestore document to retrieve.
 * @returns {ShopifyAndSecretURLAnalyticsTypes} Firestore document data.
 */
export const getSecretURLPNRVisitorById = async (id: string) => {
  const result = await getDoc(doc(firestoreDB, "secretURLPNRVisitors", id));
  return result;
};

/**
 * @param {string} id - ID of the Firestore document to be updated.
 * @param {ShopifyAndSecretURLAnalyticsTypes} data - data of the document to be updated.
 * @param {string} data.email - Email of the rescuer.
 * @param {number} data.buttonClickCount - Number of times rescue button clicked.
 */
export const updateSecretURLPNRVisitor = async (id: string, data: ShopifyAndSecretURLAnalyticsTypes) => {
  await updateDoc(doc(firestoreDB, "secretURLPNRVisitors", id), data);
};

/**
 * @param {string} token_hash - A unique secret hash of the PNR.
 * @param {string} email - Email address of the rescuer.
 * @param {string} secret_url_type - Type of the secret URL.
 * @returns {{status: number, message: string}} status & message.
 */
export const claimSecretURLPNR = (token_hash: string, email: string, secret_url_type: string) => {
  const result = httpsCallable<
    { token_hash: string; email: string; secret_url_type: string },
    { status: number; message: string }
  >(getFunctions(), "claimSecretURLPNR");

  return result({ token_hash, email, secret_url_type });
};
